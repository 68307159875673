import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./home.scss";
import Countdown from "react-countdown";
import moment from "moment";
import { Modal } from "antd";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <p>Form is closed</p>;
    } else {
        // Render a countdown
        return (
            <p>
                {days}days {hours}hour {minutes}min {seconds}sec remaining
            </p>
        );
    }
};

export default function HomePage() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // show popup of image on load

    // const [show, setShow] = useState(true);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    useEffect(() => {
        showModal()
    }, []);



    return (
        <div className="homepage">

            <Modal title="Notice" open={isModalOpen}
                footer={null}
                onCancel={handleCancel}
            >

                <img src='/popup.png' alt='popup'
                 style={{
                    width: '100%',
                }}
                />
            </Modal>

            <div className="wrap">
                <div className="flex jcsb ci">
                    <main>
                        <h1>SOMAT ENTRANCE 2025 (SPRING)</h1>
                        <br />
                        <h2>
                            <b>Admission is open for</b>
                        </h2>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <h2 style={{ fontSize: '30px', width: '100%' }}>
                                <b>1. MBA</b>
                            </h2>
                            {/* <p>Application Deadline: January 4, 2023 (4PM)</p> */}
                            {/* <Countdown
              date={moment("2023-01-04 16:00:00").valueOf()}
              renderer={renderer}
            /> */}

                            <h2 style={{ fontSize: '30px', width: '100%' }}>
                                <b>2. MBA FC</b>
                            </h2>

                            <h2 style={{ fontSize: '30px', width: '100%' }}>
                                <b>3. MBA GLM</b>
                            </h2>
                            {/* <p>a. Extended Deadline: January 24, 2023 (4PM)</p> */}
                            {/* <Countdown
              date={moment("2023-01-24 24:00:00").valueOf()}
              renderer={renderer}
            /> */ <h2 style={{ fontSize: '30px', width: '100%' }}>
                                <b>3. MBA IT</b>
                            </h2>}

                            {/* <h2 style={{ fontSize: '30px', width: '100%' }}>
                                <b>3. MBA-IT </b>
                            </h2> */}
                        </div>
                        <br />
                        <p><b>Application Deadline: 23rd January 2025 </b></p>
                        <Countdown
                            date={moment("2025-01-23 16:00:00").valueOf()}
                            renderer={renderer}
                        />
                        <br />

                        <p><b>Written Exam Test:</b> 25th January 2025 </p>
                        <Countdown
                            date={moment("2025-01-25 23:59:00").valueOf()}
                            renderer={renderer}
                        />
                        <br />
                        <br />
                        <p>
                            <b>Note:</b> <br />

                            <i>Students can visit SOMTU for personal counselling (10:00 AM - 03:00 PM) </i>

                            <br />
                            <b>
                                <i>noreply@somatmba.com </i>
                            </b>
                            is not being monitered, <br /> please email your queries to
                            <b>
                                <i> mgmt.somtu@gmail.com </i>
                            </b>
                            or call at
                            <b>
                                <i> 01-4332719</i>, <i>01-5907928</i>
                            </b>
                        </p>
                    </main>

                </div>
                <Link to="/rules">
                    <button className="apply-now">Apply Now</button>
                </Link>
                <br />
                <br />
                <br />
            </div>
        </div>
    );
}
